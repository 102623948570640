@use './../variables/weight' as fw;
@use './../variables/breakpoints' as *;
@use './../custom/colors' as c;

$site-font-family: 'Overpass', sans-serif;

body {
  font-family: $site-font-family;
  color: var(--black);

  background-color: map-get(c.$default-colors, #{black});
}

textarea {
  resize: none;

  &::placeholder {
    font-style: italic;
    font-weight: fw.$light;
    color: #00000040;
  }
}

p:last-of-type {
  margin-bottom: 0;
}

hr {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-color: #b4c0c5;
}

b,
strong {
  font-weight: fw.$bold;
}

video {
  max-width: 100%;
  height: auto;
  display: block;

  &::-internal-media-controls-overlay-cast-button {
    display: none;
  }
}

small {
  font-size: 0.75rem;
  font-weight: fw.$light;
}

a {
  color: var(--primary);
  font-weight: fw.$light;
  text-decoration: none;

  &[href^='tel:'],
  &[href^='mailto:'] {
    font-weight: fw.$medium;
    text-decoration: underline;
  }

  &:hover {
    color: var(--primary-dark);
  }
}

ul {
  &:not(.list-unstyled) {
    list-style: none;
    padding: 0;
    margin: 0;

    li::before {
      content: '—';
      margin-right: 0.5rem;
    }
  }
}
