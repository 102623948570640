.intrinsic-space {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 0;
  align-items: flex-end;
  justify-self: flex-start;

  &--end {
    gap: 3.5rem 0;
    justify-content: flex-end;
  }

  > .intrinsic-space__start {
    flex: 1 1 40ch;
  }
}
