@use './../custom/colors' as c;

$header-button-bg-color: map-get(c.$default-colors, #{white});
$header-button-line-color: map-get(c.$default-colors, #{black});

$header-button-size: 3rem;

.header-button {
  background: $header-button-bg-color;

  width: $header-button-size;
  height: $header-button-size;
  border-radius: 9999px;

  position: relative;

  cursor: pointer;

  &:after {
    content: '';

    position: absolute;
    top: 0;
    left: 50%;

    background-color: inherit;

    width: 100vh;
    height: 100%;

    z-index: -1;
  }

  &-container {
    width: 100%;
    height: 100%;

    position: relative;

    display: grid;
    place-items: center;
  }

  &-icon {
    transform: translateX(0.5rem);

    &,
    &:before,
    &:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      transition:
        background ease 0.3s,
        top ease 0.3s 0.3s,
        transform ease 0.3s;
      width: 1.25rem;
      background-color: $header-button-line-color;

      border-radius: 9999px;
    }

    &:before,
    &::after {
      width: 1.5rem;
    }

    &:before {
      top: -6px;
      left: -4px;
    }

    &:after {
      top: 6px;
      left: -4px;
    }
  }

  body.menu-active & {
    .header-button-icon {
      background: transparent;

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }

      &:before,
      &:after {
        top: 0;
        transition:
          top ease 0.3s,
          transform ease 0.3s 0.3s;
      }
    }
  }
}
