@use './../variables/breakpoints' as *;

.grid-collapse {
  display: grid;
  align-items: end;

  &--lg {
    @include breakpoint-min-lg {
      > * {
        grid-area: 1/1;

        &:first-child {
          padding-bottom: 5rem;
          position: relative;
          z-index: 1;
        }

        &:last-child {
          // position: relative;
          // z-index: -1;
        }
      }
    }
  }
}
