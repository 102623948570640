@use './../variables/breakpoints' as *;

.grid {
  display: grid;

    @include breakpoint-min-md {

      &-md{
        display: grid !important;
      }

  }

  &_2-col{
    grid-template-columns: 1fr 1fr
  }
}
