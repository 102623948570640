.blur {
  &-section {
    padding-block: 0 !important;
    overflow: hidden;

    &-container {
      position: relative;
    }
  }

  &-wrapper {
    position: absolute;
    inset: 0;
  }

  &-container {
    display: flex;
    flex-direction: column;
    position: relative;

    padding-block: 6rem;
    min-height: 100vh;
  }

  &-body {
    margin-block: auto;
  }

  &-footer {
    text-align: center;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 2.5rem;
  }

  &-image {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }

  &-effect {
    // background-image: url("/img/backdrop.png");

    background-color: rgba(#fff, 0.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    transform: translate3d(0, 0, 0) translateZ(0);

    // backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;

    position: absolute;
    inset: 0;

    &-wrapper {
      width: 100%;
      height: 100%;

      background-color: rgba(#fff, 0.3);

      position: absolute;
      inset: 0;
    }
  }
}
