.aspect-ratio {
  aspect-ratio: var(--aspect-ratio);

  &.ratio {
    &--16-9 {
      --aspect-ratio: 16/9;
    }

    &--4-3 {
      --aspect-ratio: 4/3;
    }

    &--1-1 {
      --aspect-ratio: 1/1;
    }
  }
}
