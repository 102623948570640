.img {
  &-square {
    aspect-ratio: 1;

    width: 100%;
    height: auto;

    object-fit: cover;
  }

  &-full {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }
}
