@use './../variables/breakpoints' as *;

section {
  --section-space-y: 4.5rem;
  background-color: inherit;

  @include breakpoint-min-md {
    --section-space-y: 9.5rem;
  }

  margin-block: var(--section-space-y);

  &:first-child {
    margin-top: 0;
  }

  &[class|='bg'] {
    margin-block: 0;
    padding-block: var(--section-space-y);

    &:last-child {
      margin-bottom: 0;
    }
  }

  &[class|='bg-white'] {
    + section[class|='bg-white'] {
      padding-top: 0 !important;
    }
  }

  // da sistemare
  &.section-specific {
    margin-block: unset;

    &[class|='bg'] {
      padding-block: unset;
    }

    > .section-specified {
      padding-block: var(--section-space-y);
    }
  }

  &.parallax-bg {
    aspect-ratio: 16/6;
    margin-block: unset !important;

    video {
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: center right;
    }

    picture {
      height: 100%;

      img {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
      }
    }
  }
}
