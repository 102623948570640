@use './../variables/breakpoints' as *;

$video-control-gap: 1rem;

.hero-video {
  position: relative;
  overflow: hidden;
  aspect-ratio: 16/ 9;

  video {
    width: 100%;
    aspect-ratio: 16/9;
  }

  .video-control {
    &-container {
      position: absolute;
      inset: 0;
      max-height: 100vh;
    }

    width: 100%;

    padding-inline: 1rem;

    position: absolute;
    bottom: 1rem;
    z-index: 1;

    display: flex;
    align-items: center;
    gap: $video-control-gap;

    @include breakpoint-min-md {
      padding-inline: 3.25rem;
    }
  }
}

@keyframes appear-video-control {
  from {
    transform: translateY(150%);
  }

  to {
    transform: translateY(0);
  }
}
