@use './../variables/breakpoints' as *;

$stroke-color: #a1a1a1;

.container {
  &.container-drawing {
    max-width: calc(1152px + calc(52px * 2));
  }
}

.line-drawing {
  &__item {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 1.5rem;

    grid-template-rows: 1fr 5.5rem;

    @include breakpoint-min-md {
      grid-template-columns: auto 1fr 1fr;
      grid-template-rows: 1fr 6.25rem;
      // margin-top: -2rem;
    }

    &.line-drawing--end {
      grid-template-rows: 1fr 6.25rem;

      @include breakpoint-max-md {
        grid-template-columns: auto 1fr 1fr;

        .line-drawing__content {
          grid-column-start: 2;
          grid-column-end: 4;
        }
      }

      @include breakpoint-min-md {
        grid-template-rows: 1fr 12.5rem;
      }
    }

    &:nth-child(odd) {
      .line-drawing__content {
        @include breakpoint-min-lg {
          padding-right: 4rem;
        }

        @include breakpoint-min-xl {
          padding-right: 6rem;
        }
      }
    }

    &:nth-child(even) {
      .line-drawing__content {
        @include breakpoint-min-lg {
          padding-left: 4rem;
        }

        @include breakpoint-min-xl {
          padding-left: 6rem;
        }
      }
    }
  }

  &__container {
  }

  &__vertical {
  }

  &__horizontal {
    grid-area: -1 / 1 / -2 / 4;
  }

  &__end {
    grid-area: 2 / 1 / 3 / 3;

    position: relative;

    .point {
      position: absolute;
      bottom: 0;
      right: 0;

      transform: translate(50%, 50%);
      // opacity: 0

      .pulse {
        &-circle,
        &-circle-2 {
          stroke: $stroke-color;
          stroke-width: 2px;
          fill: none;
          transform: translate3d(0, 0, 0);
        }

        &-circle {
          animation: pulse 1s cubic-bezier(0.39, 0.54, 0.41, 1.5) infinite;
        }

        &-circle-2 {
          animation: pulse-2 1s cubic-bezier(0.39, 0.54, 0.41, 1.5) infinite;
        }
      }
    }
  }

  // icon, title, description content
  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    padding-block: 2rem;

    @include breakpoint-min-md {
      padding-inline: 1.5rem;
    }
  }

  // reverse horizontal line
  &--flip {
    @include breakpoint-min-md {
      grid-template-columns: 1fr 1fr auto;
    }

    .line-drawing {
      &__content {
        grid-column-start: 2;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }

      &__vertical {
        grid-column-start: 3;
        grid-column-end: 3;
      }

      &__horizontal {
        transform: scaleX(-1);
      }
    }
  }
}

.line {
  &--vertical {
    width: 2px;
  }

  &--horizontal {
  }

  &__path,
  &__dash {
    fill: none;
  }

  &__path {
    stroke: $stroke-color;
    // stroke-dasharray: 262; // this is the entire length of the line
    // stroke-dashoffset: 425; // this is the entire length of the line
  }

  &__dash {
    stroke: #000; // this must match the background color
    stroke-dasharray: 11; // draws a 10px dash line with a 16px gap between
    stroke-width: 2; // make the dashed line slightly bigger than the one it's covering
  }
}

@keyframes pulse {
  0% {
    r: 4;
  }

  50% {
    r: 16;
  }

  60% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes pulse-2 {
  50% {
    r: 4;
  }

  100% {
    r: 16;
  }
}
