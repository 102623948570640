@use './../variables/breakpoints' as *;
@use './../variables/weight' as fw;
@use './../custom/colors' as c;

$offcanvas-height: 90vh;
$offcanvas-body-padding-block: 2.5rem;
$offcanvas-body-bg-color: map-get(c.$default-colors, #{white});
$offcanvas-overlay-bg-color: map-get(c.$main-colors, primary);

$offcanvas-social-link-circle-bg-color: map-get(c.$default-colors, #{white});

.offcanvas {
  --bs-offcanvas-height: #{$offcanvas-height};
  --bs-offcanvas-padding-x: 0;
  --bs-offcanvas-padding-y: 1.5rem;

  .btn-close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;

    z-index: 2;

    @include breakpoint-min-md {
      top: 2rem;
      right: 3rem;
    }
  }

  &-title {
    font-family: 'Poppins', sans-serif;
    font-size: clamp(
      1.5rem,
      1.2214285714285715rem + 1.1428571428571428vw,
      2.25rem
    );
    letter-spacing: -0.0125rem;
    font-weight: fw.$medium;

    @include breakpoint-max-md {
      padding-right: 2.5rem;
    }
  }

  &-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    background-color: $offcanvas-body-bg-color;

    padding-block: $offcanvas-body-padding-block;

    // overflow-y: auto;

    @include breakpoint-max-md {
      padding-bottom: 5rem;
    }
  }

  &-content {
    .display-3 {
      padding-right: 3rem;
    }

    @include breakpoint-min-md {
      margin: {
        top: auto;
        bottom: auto;
      }
    }
  }

  &-button {
    @include breakpoint-max-md {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      padding: {
        inline: 1.5rem; // container padding
        block: 1.5rem;
      }

      background-color: $offcanvas-body-bg-color; // offcanvas bg color

      .btn {
        width: 100%;
        justify-content: center;
      }
    }
  }

  &-footer {
    &-content {
      padding-top: 1.5rem;
      border-top: 1px solid currentColor;
    }
  }

  // send email
  &-overlay {
    position: absolute;
    z-index: 1;
    inset: 0;

    background-color: $offcanvas-body-bg-color;
    display: grid;

    opacity: 0;
    pointer-events: none;

    &.active {
      pointer-events: unset;
    }

    > * {
      grid-area: 1/1;
    }

    &-content {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    .offcanvas {
      &-body {
        &.purple {
          background-color: $offcanvas-overlay-bg-color;
          color: #fff;

          .social-arrow .social-arrow-link.social-arrow-link--circle {
            background-color: rgba($offcanvas-social-link-circle-bg-color, 20%);
          }
        }
      }

      &-content {
        flex: 1;
        display: flex;
        align-items: center;
      }

      &-social-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem 1.25rem;

        margin-top: 1.5rem;
      }
    }
  }
}
