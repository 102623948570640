.word-mask {
  .word {
    display: inline-flex;
    overflow: hidden;
    line-height: normal;
    margin-top: -0.5em;

    .char {
      transform: translateY(110%);
    }
  }
}
