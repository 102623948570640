@use './../variables/breakpoints' as *;

$title-font-family: 'Poppins', sans-serif;

@mixin h1 {
  font-size: clamp(
    1.75rem,
    1.7142857142857142rem + 0.17857142857142858vw,
    1.875rem
  );
}

@mixin h2 {
  font-size: clamp(
    1.625rem,
    1.5892857142857142rem + 0.17857142857142858vw,
    1.75rem
  );
}

@mixin h3 {
  font-size: clamp(
    1.5rem,
    1.4642857142857142rem + 0.17857142857142858vw,
    1.625rem
  );
  line-height: 3.125rem;
}

@mixin h4 {
  font-size: clamp(
    1.375rem,
    1.3392857142857142rem + 0.17857142857142858vw,
    1.5rem
  );
}

@mixin h5 {
  font-size: clamp(
    1.25rem,
    1.2142857142857142rem + 0.17857142857142858vw,
    1.375rem
  );
}

@mixin h6 {
  font-size: clamp(
    1.125rem,
    1.0892857142857142rem + 0.17857142857142858vw,
    1.25rem
  );
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $title-font-family;
}

h1,
.h1 {
  @include h1;
}

h2,
.h2 {
  @include h2;
}

h3,
.h3 {
  @include h3;
}

h4,
.h4 {
  @include h4;
}

h5,
.h5 {
  @include h5;
}

h6,
.h6 {
  @include h6;
}
