@use './../variables/breakpoints' as *;

.masonry-grid {
  // Overwrite JS MagicGrid styles
  @include breakpoint-max-md {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    height: unset !important;
  }

  .masonry-grid-item {
    width: 100%;

    // Overwrite JS MagicGrid styles
    @include breakpoint-max-md {
      position: static !important;
      transform: unset !important;

      // For Behance card
      &.last-one {
        order: 100;
      }
    }

    @include breakpoint-min-md {
      // * Width elmenti - Magic Grid gutter
      width: calc(50% - 100px);
    }
  }
}
