@use './../custom/colors' as c;
@use './../variables/breakpoints' as *;

$footer-padding-bottom: 1.5rem;
$footer-spacing: 2rem;
$footer-md-spacing: 7.5rem;
$footer-font-size: 0.875rem;

$footer-color: map-get(c.$default-colors, #{white});
$footer-bg-color: map-get(c.$default-colors, #{black});

$footer-copyright-font-size: 0.75rem;
$footer-copyright-border-color: rgba(map-get(c.$default-colors, #{white}), 0.6);
$footer-copyright-padding-top: $footer-padding-bottom;

.footer {
  // TODO: Test
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: -100%;
  right: unset;

  color: $footer-color;
  background-color: $footer-bg-color;

  display: flex;
  flex-direction: column;
  gap: $footer-spacing;

  font-size: $footer-font-size;

  padding: {
    top: $footer-spacing;
    bottom: $footer-padding-bottom;
  }

  &.visible {
    position: fixed;

    left: 0;
    right: 0;

    z-index: 1;
  }

  @include breakpoint-min-md {
    gap: $footer-md-spacing;
    padding-top: $footer-md-spacing;
  }

  &__container {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.5rem;

    @include breakpoint-min-xl {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include breakpoint-max-md {
      .footer {
        &__logo {
          margin-bottom: 1rem;
        }

        &__info {
          margin-bottom: 0.5rem;
        }
      }
    }

    @include breakpoint-min-md {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.25rem;

      grid-template-rows: 2.5rem auto; // Logo Height
    }

    @include breakpoint-min-xl {
      grid-template: {
        columns: 0.5fr 1fr;
        rows: 4rem auto;
      }

      .footer__info {
        margin-top: -1.5rem;
      }
    }
  }

  &__right {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.5rem;

    @include breakpoint-min-xl {
      display: grid;
      grid-template-columns: 1fr minmax(auto, 272px);
    }
  }

  &__social {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem 1.25rem;

    @include breakpoint-min-xl {
      display: flex;
      flex-direction: column;

      margin-top: -1rem;

      order: 1;
    }
  }

  &__menu {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  &__info {
    @include breakpoint-min-md {
      grid-area: auto / 1 / auto / 1;
    }
  }

  &__iva {
    display: flex;
    align-items: center;

    @include breakpoint-min-md {
      flex-direction: column;
      align-items: flex-start;

      grid-area: auto / 1 / auto / 1;
    }

    @include breakpoint-min-lg {
      grid-area: auto / 2 / auto / 2;
    }

    @include breakpoint-max-md {
      gap: 0.5rem;

      > * {
        display: flex;
        gap: 0.5rem;
      }

      > :not(:first-child) {
        &:before {
          content: '—';
        }
      }
    }
  }

  // Inner menu
  &__logo-address {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__info-iva {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__copyright {
    border-top: 1px solid $footer-copyright-border-color;
    padding-top: $footer-copyright-padding-top;

    display: flex;
    flex-direction: column;
    gap: 2rem;

    @include breakpoint-min-md {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
}

.copyright {
  &__cookie {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    font-size: $footer-copyright-font-size;

    @include breakpoint-min-md {
      gap: 3.25rem;
    }
  }
}
