// vendor
@use 'vendor/bootstrap/bootstrap.scss';
@use 'vendor/icomoon.scss';
@use 'vendor/aos.css';
@use 'vendor/jquery.convform.min.css'; // chatbot
// @use "vendor/swiper-bundle.min.css";

// variables
// @use "variables/weight";
@use 'variables/breakpoints';

// base
@use 'base/root';
@use 'base/reset';

// utilities
@use 'utilities/position';
@use 'utilities/background';
@use 'utilities/text';
@use 'utilities/canvas';
@use 'utilities/mix-blend-mode';
@use 'utilities/sizing';
@use 'utilities/font-weight';
@use 'utilities/align-items';
@use 'utilities/grid-collapse';
@use 'utilities/font-family';
@use 'utilities/aspect-ratio';
@use 'utilities/spacing-clamp';
@use 'utilities/grid';
// @use "utilities/spacing";

// content
@use 'content/typography';
@use 'content/title';
@use 'content/display';
@use 'content/images';

// components
@use 'components/badge';
// @use "components/bar";
@use 'components/buttons';
// @use "components/calendar";
@use 'components/fancy-list';
@use 'components/fix-cta';
@use 'components/modal';
@use 'components/navigation';
@use 'components/social-list';
@use 'components/nav-item';
@use 'components/link';
@use 'components/header-button';
@use 'components/language-selector';
@use 'components/video-hero';
@use 'components/menu';
@use 'components/logo';
@use 'components/megamenu';
@use 'components/card';
@use 'components/slider';
@use 'components/accordion';
@use 'components/arrow';
@use 'components/offCanvas';
@use 'components/close';
@use 'components/hr';
@use 'components/masonry-grid';
@use 'components/fixed-parallax-video';
@use 'components/word-mask';
@use 'components/loading';
@use 'components/social-arrow';
@use 'components/btn-contact'; // chatbot
@use 'components/chatbot'; // chatbot

// form
@use 'form/form-control';
@use 'form/form-floating';
@use 'form/form-select';
@use 'form/checks-radios';
@use 'form/form-label';
@use 'form/upload-file';

// sections
@use 'sections/section';
@use 'sections/blur-section';
// @use "sections/section-grid";
@use 'sections/line-drawing';
@use 'sections/innovation';
@use 'sections/attachment-fixed';

// layout
@use 'layout/body';
@use 'layout/header';
@use 'layout/footer';
@use 'layout/containers';
@use 'layout/intrinsic-space';
@use 'layout/main';
@use 'layout/columns';

// animation
@use 'animation/mask-image';
@use 'animation/line-animation';
@use 'animation/work-list';


.mt-40 {
  margin-top: 2.5rem;
}

.text-collapse {
  display: grid;
  overflow: hidden;

  > * {
    grid-area: 1/1;
  }
}

.section-brand{
  &_home{
    margin: {
      right:0;
      left: 0;
    };
    &, .accordion{
    width:100%;
    }
  }
}
