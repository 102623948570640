@use './../custom/colors' as c;

$logo-fill: map-get(c.$default-colors, #{white});
$logo-fill-purple: map-get(c.$main-colors, primary);

.logo {
  width: 7.5rem;
  height: auto;
  fill: $logo-fill;
  transition: fill 0.5s ease-in-out;

  &.logo-purple {
    fill: $logo-fill-purple;
  }
}
