@use './../custom/colors' as c;
@use './../variables/weight' as fw;

$upload-file-label-min-height: 8rem;

$upload-file-label-color: rgba(map-get(c.$main-colors, primary), 40%);
$upload-file-label-bg-color: rgba(map-get(c.$main-colors, primary), 5%);
$upload-file-label-border-color: rgba(map-get(c.$main-colors, primary), 25%);

// hover
$upload-file-label-hover-bg-color: rgba(map-get(c.$main-colors, primary), 15%);

// active
$upload-file-label-active-color: map-get(c.$default-colors, #{white});
$upload-file-label-active-bg-color: map-get(c.$main-colors, primary);

.upload-file {
  position: relative;

  input {
    opacity: 0;

    position: absolute;
    inset: 0;
    z-index: -1;

    & ~ {
      label {
        display: grid;
        place-items: center;

        width: 100%;
        min-height: $upload-file-label-min-height;

        text-transform: uppercase;

        font: {
          size: 0.875rem;
          weight: fw.$medium;
        }

        color: $upload-file-label-color;
        background-color: $upload-file-label-bg-color;
        border: 1px solid $upload-file-label-border-color;

        cursor: pointer;

        transition: {
          property: background-color, color;
          duration: 0.2s;
          timing-function: ease-in-out;
        }

        &:hover {
          background-color: $upload-file-label-hover-bg-color;
        }

        &:active {
          color: $upload-file-label-active-color;
          background-color: $upload-file-label-active-bg-color;
        }
      }
    }
  }
}
