.loading {
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;

  z-index: 99999;

  &__square {
    position: absolute;
    z-index: -1;
    inset: 0;
  }

  &__container {
    display: grid;
    place-items: center;
    text-align: center;

    background-color: #d50058;

    height: 100%;
  }

  &--loading-start {
    display: block;

    left: 0;
    right: 0;
  }

  &-end {
    display: none;
  }

  &__progress {
    display: flex;
    align-items: center;
    gap: 1rem;

    color: #fff;
  }

  &__line {
    width: 300px;
    height: 1px;
    background-color: #ffffff63;

    position: relative;

    display: flex;
    align-items: center;
    gap: 1rem;

    &--progress {
      position: absolute;
      left: 0;
      top: 0;

      width: 0;
      height: 100%;
      background-color: currentColor;
    }

    &--number {
      font-weight: 600;
      font-size: 0.75rem;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }
}
