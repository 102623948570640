@use './../custom/colors' as *;

// generate background colors
@each $color, $value in $main-colors {
  .bg-#{$color} {
    background-color: var(--#{$color}) !important;
  }
}

.bg-contain {
  background-size: contain !important;
}
