@use './../variables/weight' as *;

.fw {
  &-medium {
    font-weight: $medium !important;
  }

  &-extralight {
    font-weight: $extra-light !important;
  }
}
