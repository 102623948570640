@use './../custom/colors' as c;
@use './../variables/weight' as fw;

$main-color: map-get(c.$default-colors, #{white});
$fs: 0.875rem;
$fw: fw.$light;

$arrow-size: 1rem;

$bottom-bg: map-get(c.$main-colors, #{primary});

.social-arrow {
  display: grid;

  color: $main-color;
  border-bottom: 1px solid rgba($main-color, 50%);

  position: relative;

  overflow: hidden;

  z-index: 0;

  font: {
    size: $fs;
    weight: $fw;
  }

  /* > * {
    grid-area: 1/1;
  } */

  &:hover {
    color: $main-color;
  }

  .social-arrow-link {
    &--label {
      padding: 1rem 1rem calc(1rem - 1px) 1rem;

      &::after {
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMCAwaDE2djE2SDB6Ii8+PHBhdGggc3Ryb2tlPSIjRkZGIiBkPSJNNCA0aDh2OE00IDEybDgtOCIvPjwvZz48L3N2Zz4=');

        width: $arrow-size;
        height: $arrow-size;

        position: absolute;
        top: 0.25rem;
        right: 0.25rem;
      }
    }

    &--circle {
      position: absolute;
      z-index: -1;

      width: 0%;
      height: auto;
      border-radius: 9999px;
      aspect-ratio: 1;

      pointer-events: none;

      background-color: $bottom-bg;

      // transform: translate(-50%, -50%);
    }
  }
}
