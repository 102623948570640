@use './../variables/breakpoints' as *;

$menu-gap: 1rem;
$menu-md-gap: 4.5rem 112px;

.menu-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  @include breakpoint-min-md {
    gap: $menu-md-gap;
    flex-direction: row;
  }
}
