.social-list {
  padding: 0;
  margin: 0;
  list-style: none;

  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.25rem;

  a {
    text-decoration: unset;

    opacity: 0.7;
    color: var(--white);

    border-radius: 0.2rem;

    border: 1px solid rgba(233, 233, 233, 0.3);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 2.5rem;
    height: 2.5rem;

    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 1;
      border-color: var(--white);
    }
  }
}
