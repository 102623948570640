@use './../custom/colors' as c;
@use './../variables/weight' as fw;
@use './../variables/breakpoints' as break;

$lang-selector-separator-bg-color: map-get(c.$default-colors, #{white});
$lang-selector-gap: 0.75rem;
$lang-selector-opacity: 0.6;
$lang-font-size: 0.875rem;

$lang-selector-link-color: map-get(c.$default-colors, #{white});

.lang {
  &-selector {
    // menu open
    body.menu-active & {
      opacity: 1;
      pointer-events: unset;

      transition: opacity 0.5s ease-in-out 0.3s;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    gap: $lang-selector-gap;

    text-transform: uppercase;

    font-size: $lang-font-size;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;

    margin-bottom: 4.5rem;

    @media (min-width: 768px) {
      margin-bottom: 0;

      justify-content: flex-start;

      position: absolute;
      top: 3rem;
      right: 10rem;
    }

    > *:not(:last-child)::after {
      content: '';

      display: block;

      width: 1.25rem;
      height: 1px;
      background-color: $lang-selector-separator-bg-color;

      opacity: $lang-selector-opacity;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    gap: $lang-selector-gap;

    &.active {
      opacity: 1;
    }
  }

  &-link {
    color: $lang-selector-link-color;
    opacity: $lang-selector-opacity;
    transition: opacity 0.2s ease-in-out;

    &.current {
      font-weight: fw.$bold;
    }

    &:hover {
      opacity: 1;
      color: $lang-selector-link-color;
    }
  }
}
