@use './../custom/colors' as c;

$form-control-padding-x: 0;

// focus
$form-control-focus-border-color: map-get(c.$default-colors, #{black});

.form {
  &-control {
    border-radius: 0;
    border-width: 0 0 1px 0;
    font-size: 0.875rem;

    &:focus {
      box-shadow: unset;
      border-color: $form-control-focus-border-color;
    }
  }
}
