@use './../custom/colors' as c;

$btn-close-size: 2.5rem;
$btn-close-inverted-bg-color: map-get(c.$default-colors, #{black});

.btn-close {
  border-radius: 9999px;
  opacity: 1;
  background: unset;
  box-sizing: border-box;
  padding: 0;

  width: $btn-close-size;
  height: $btn-close-size;

  &-inverted {
    background-color: $btn-close-inverted-bg-color;

    .btn-close-icon {
      color: #fff;
      font-size: 1.25rem;
    }
  }

  &-icon {
    display: grid;
    place-items: center;

    * {
      font-size: inherit !important;
    }
  }
}
