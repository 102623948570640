@use './../variables/breakpoints' as *;

.fixed-parallax-video {
  margin: 0 !important;
  background: transparent !important;

  min-height: 100vw;

  position: relative;

  overflow: hidden;
  z-index: -1;
  pointer-events: none;

  @include breakpoint-min-md {
    min-height: 70vh;
  }

  img {
    width: 100%;
  }

  img,
  video {
    object-fit: cover;
    object-position: center;

    display: none;

    position: absolute;
    inset: 0;

    @include breakpoint-min-md {
      min-height: 100vh;
    }
  }

  &.video-start {
    img,
    video {
      display: block;

      @include breakpoint-min-md {
        position: fixed;
      }
    }
  }
}
