@use './../variables/breakpoints' as *;

$container-default-gutter: 9.75rem;
$container-gutters: (
  'sm': 3.25rem,
);

$container-default-max-width: 1384px; // // max-width + gutter
$container-skinny-max-width: 1176px; // // max-width + gutter

.container {
  --bs-gutter-x: 3rem;
  max-width: $container-default-max-width;

  &.container {
    &-skinny {
      @include breakpoint-min-md {
        max-width: calc(768px + 24px);
      }

      @include breakpoint-min-lg {
        max-width: $container-skinny-max-width;
      }
    }
  }

  &.container-separate {
    @include breakpoint-min-xl {
      max-width: 1340px;
    }
  }
}
