@use './../variables/weight' as fw;

$nav-item-color: #fff;
$nav-item-font-family: 'Poppins', sans-serif;
$nav-item-font-size: clamp(
  1.5rem,
  1.0357142857142856rem + 2.3214285714285716vw,
  3.125rem
);
$nav-item-font-weight: fw.$regular;
$nav-item-line-height: 3.625rem;

$nav-item-sup-font-family: 'Overpass', sans-serif;
$nav-item-sup-font-size: 0.8125rem;
$nav-item-sup-font-weight: fw.$light;
$nav-item-sup-line-height: 0.875rem;

.nav-item {
  color: $nav-item-color;

  font: {
    family: $nav-item-font-family;
    size: $nav-item-font-size;
    weight: $nav-item-font-weight;
  }

  transition: opacity 0.2s ease-in-out;

  display: inline-flex;
  align-items: flex-start;
  gap: 0.5rem;
  opacity: 0.4;

  span {
    line-height: $nav-item-line-height;
  }

  &.active {
    opacity: 1;

    span {
      font-style: italic;
    }
  }

  &:hover {
    opacity: 1;
    color: $nav-item-color;
  }

  &-number {
    font: {
      family: $nav-item-sup-font-family;
      size: $nav-item-sup-font-size;
      weight: $nav-item-sup-font-weight;
    }

    line-height: $nav-item-sup-line-height;
  }
}
