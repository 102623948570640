.mb {
  &-24-48 {
    margin-bottom: clamp(
      1.5rem,
      0.9428571428571428rem + 2.2857142857142856vw,
      3rem
    );
  }

  &-40-80 {
    margin-bottom: clamp(
      2.5rem,
      1.5714285714285714rem + 3.8095238095238098vw,
      5rem
    );
  }
}

.my {
  &-48-60 {
    margin-block: clamp(
      3rem,
      2.7214285714285715rem + 1.1428571428571428vw,
      3.75rem
    );
  }
}
