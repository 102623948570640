@use './../variables/weight' as fw;
@use './../variables/breakpoints' as *;

.fancy-list {
  --fancy-list-spacing: 11rem;

  // display: grid;
  display: flex;
  flex-direction: column;

  // margin-top: calc(var(--fancy-list-spacing) * 0.5);

  // gap: calc(var(--fancy-list-spacing) * 0.5);

  @include breakpoint-min-lg {
    margin-top: 0;
  }

  &__title {
    margin-top: -2rem;

    @include breakpoint-min-md {
      margin-left: -5rem;
      margin-top: unset;
    }

    > img {
      position: relative;
      z-index: 1;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    position: relative;
    z-index: 1;

    // grid-area: 1 / 1;

    // margin-top: var(--fancy-list-spacing);

    @include breakpoint-min-md {
      margin-top: unset;
    }

    transition: opacity 0.2s ease-in-out;
  }

  &__number {
    object-fit: contain;

    position: absolute;

    top: calc(var(--fancy-list-spacing) * -1.3);

    @include breakpoint-min-md {
      top: 0;
      left: calc(var(--fancy-list-spacing) * -1);
    }

    width: 15rem;
    height: auto;

    z-index: -1;
  }

  &__sticky-gradient {
    position: relative;

    > .gradient {
      width: 100%;
      height: 5rem;

      @include breakpoint-min-md {
        height: 10rem;
      }

      position: sticky;

      pointer-events: none;

      z-index: 2;

      &.top {
        top: 90px;

        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      &.bottom {
        bottom: 0;

        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }
  }

  &__ul {
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    flex-direction: column;

    font-size: 1.375rem;

    li {
      &:not(:last-child):after {
        content: '';

        margin-block: 3rem;

        display: block;

        width: 100%;
        height: 1px;
        background-color: var(--white);

        opacity: 0.3;
      }
    }
  }
}
