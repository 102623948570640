$sizes: (
  32: '2rem',
  40: '2.5rem',
  48: '3rem',
);

@each $size, $value in $sizes {
  .gx-#{$size} {
    --bs-gutter-x: #{$value};
  }

  .gy-#{$size} {
    --bs-gutter-y: #{$value};
  }

  .gap-#{$size} {
    gap: #{$value} !important;
  }
}
