@use './../variables/breakpoints' as *;

section {
  .section-innovation {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_container {
      display: flex;
      flex-direction: column;
      gap: clamp(2rem, 0.3285714285714285rem + 6.857142857142858vw, 6.5rem);

      /* @include breakpoint-min-lg {
        gap: 12.5rem;
      } */
    }

    .accordion-body{
      .display-6{
      font-size: clamp(
        1rem,
        0.9535714285714285rem + 0.19047619047619047vw,
        1.125rem
      );
      line-height: clamp(
        1.25rem,
        1.1803571428571429rem + 0.2857142857142857vw,
        1.4375rem
      );
      }
    }
  }

  .innovation{
    &-titles{
      display: grid;
      &>*{
        grid-area: 1 / 1;
      }
    }
    
  }


}
